import React from 'react';

import { Link } from 'react-router-dom';

import styles from './Header.module.css';

const Header = props => (
    <div className="row" style={{ borderBottom: "3px solid #FF503E" }}>
        <div className="col-md-12">
            <div className="row">
                <div className="col-md-2" >
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <Link to="/Home">
                            <img src={require("../../../assets/img/logo-2.png")} className="img-fluid" alt="logo" style={{ margin: '5px 0 10px' }} />
                        </Link>
                    </div>
                </div>
                <div className="col-md-10 d-flex align-items-center" style={{ border: '0px 5px 0px 0px solid #FF503E' }}>
                    <h3 className={styles.headerTitle}>{props.restaurantName}</h3>
                </div>
            </div>
        </div>
    </div>
);

export default Header;

