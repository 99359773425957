import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { editVirtualRestaurant } from '../../../Views/VirtualRest/actions';

import Accordion from '../../Accordion';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { TextInput } from '../../TextInput';

class Edit extends Component {

    state = {
        restaurantName: this.props.restaurant.restaurantName,
        cuisine: this.props.restaurant.cuisine,
        website: this.props.restaurant.website,
        supportNumber: this.props.restaurant.supportCallNumber,
        email: this.props.restaurant.email,
        password: this.props.restaurant.accountDetails.emailPassword,
        menuLink: this.props.restaurant.menuLink,
        kitchen: this.props.restaurant.kitchenInstructionsLink,
        logo: this.props.restaurant.logoFilesLink,
        pictures: this.props.restaurant.foodPicturesLink,
        address: this.props.restaurant.address,
        uberUsername: this.props.restaurant.accountDetails.uberEats.username,
        uberPassword: this.props.restaurant.accountDetails.uberEats.password,
        uberPin: this.props.restaurant.accountDetails.uberEats.pin,
        doorUsername: this.props.restaurant.accountDetails.doorDash.username,
        doorPassword: this.props.restaurant.accountDetails.doorDash.password,
        doorTabletUsername: this.props.restaurant.accountDetails.doorDash.tabletUsername,
        doorPin: this.props.restaurant.accountDetails.doorDash.pin,
        grubUsername: this.props.restaurant.accountDetails.grubHub.username,
        grubPassword: this.props.restaurant.accountDetails.grubHub.password,
        postUsername: this.props.restaurant.accountDetails.postmates.username,
        postPassword: this.props.restaurant.accountDetails.postmates.password,
        caviarUsername: this.props.restaurant.accountDetails.caviar.username,
        caviarPassword: this.props.restaurant.accountDetails.caviar.password, 

        error: { // check for error
            haveError: false,
            errorId: ''
        }
    }

    submitEditHandler = () => {
        // Check if each input is filled
        const stateEntries = Object.entries(this.state);
        for(let i = 0; i < stateEntries.length; i++) {
            const entry = stateEntries[i];
            if(entry[1] === '' && entry[0] !== 'error') {
                const error = {
                    haveError: true,
                    errorId: entry[0]
                }
                return this.setState({ error });
            }
        }

        const data = {
            token: window.sessionStorage.getItem('token'),
            restaurantName: this.state.restaurantName,
            cuisine: this.state.cuisine,
            website: this.state.website,
            supportNumber: this.state.supportNumber,
            email: this.state.email,
            password: this.state.password,
            menuLink: this.state.menuLink,
            kitchen: this.state.kitchen,
            logo: this.state.logo,
            pictures: this.state.pictures,
            address: this.state.address,
            uberUsername: this.state.uberUsername,
            uberPassword: this.state.uberPassword,
            uberPin: this.state.uberPin,
            doorUsername: this.state.doorUsername,
            doorPassword: this.state.doorPassword,
            doorTabletUsername: this.state.doorTabletUsername,
            doorPin: this.state.doorPin,
            grubUsername: this.state.grubUsername,
            grubPassword: this.state.grubPassword,
            postUsername: this.state.postUsername,
            postPassword: this.state.postPassword,
            caviarUsername: this.state.caviarUsername,
            caviarPassword: this.state.caviarPassword, 
        }

        const id = this.props.match.params.restaurantId;
        this.props.editVirtualRestaurant(id, data);
    }

    render() {

        return (
            <Accordion title='Edit Virtual Kitchen Details'>
                <Grid container>
                    <Grid item xs>
                        <div style={{ width: '50%' }}>
                            <TextInput
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="name"
                                onChange={e => this.setState({ restaurantName: e.target.value })}
                                label="Name"
                                name="name"
                                value={this.state.restaurantName}
                                errorText={this.state.error.errorId === 'restaurantName' ? 'Please Enter Restaurant name' : null}
                            />
                            <TextInput
                                variant="outlined"
                                margin="normal"
                                onChange={e => this.setState({ cuisine: e.target.value })}
                                fullWidth
                                id="Cuisine"
                                label="Cuisine"
                                name="Cuisine"
                                value={this.state.cuisine}
                                errorText={this.state.error.errorId === 'cuisine' ? 'Please Enter Cuisine' : null}
                            />
                            <TextInput
                                variant="outlined"
                                margin="normal"
                                onChange={e => this.setState({ website: e.target.value })}
                                fullWidth
                                id="Website"
                                label="Website"
                                name="Website"
                                value={this.state.website}
                                errorText={this.state.error.errorId === 'website' ? 'Please Enter Website' : null}
                            />
                            <TextInput
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={e => this.setState({ supportNumber: e.target.value })}
                                id="Support"
                                label="Support Call Numbers:"
                                name="Support"
                                value={this.state.supportNumber}
                                errorText={this.state.error.errorId === 'supportNumber' ? 'Please Enter Support Number' : null}
                            />
                            <TextInput
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={e => this.setState({ email: e.target.value })}
                                id="Email"
                                label="Email"
                                name="Email"
                                value={this.state.email}
                                errorText={this.state.error.errorId === 'email' ? 'Please Enter Email' : null}
                            />
                            <TextInput
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={e => this.setState({ password: e.target.value })}
                                id="EmailPassword"
                                label="Email Password"
                                name="Email"
                                value={this.state.password}
                                errorText={this.state.error.errorId === 'password' ? 'Please Enter Password' : null}
                            />
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div style={{ width: '50%' }}>
                            <TextInput
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={e => this.setState({ menuLink: e.target.value })}
                                id="Menu-Link"
                                label="Menu Link"
                                name="Menu-Link"
                                value={this.state.menuLink}
                                errorText={this.state.error.errorId === 'menuLink' ? 'Please Enter Menu Link' : null}
                            />
                            <TextInput
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={e => this.setState({ kitchen: e.target.value })}
                                id="Kitchen-Instructions"
                                label="Kitchen Instructions"
                                name="Kitchen-Instructions"
                                value={this.state.kitchen}
                                errorText={this.state.error.errorId === 'kitchen' ? 'Please Enter Kitchen Instructions' : null}
                            />
                            <TextInput
                                variant="outlined"
                                margin="normal"
                                onChange={e => this.setState({ logo: e.target.value })}
                                fullWidth
                                id="Logo-Files"
                                label="Logo Files"
                                name="Logo-Files"
                                value={this.state.logo}
                                errorText={this.state.error.errorId === 'logo' ? 'Please Enter Logo url' : null}
                            />
                            <TextInput
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={e => this.setState({ pictures: e.target.value })}
                                id="Food-Pictures"
                                label="Food Pictures"
                                name="Food-Pictures"
                                value={this.state.pictures}
                                errorText={this.state.error.errorId === 'pictures' ? 'Please Enter Picture Url' : null}
                            />
                            
                            <TextInput
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                onChange={e => this.setState({ address: e.target.value })}
                                id="Address"
                                label="Address"
                                name="Address"
                                value={this.state.address}
                                errorText={this.state.error.errorId === 'address' ? 'Please Enter Address' : null}
                            />
                        </div>
                    </Grid>
                </Grid>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <th><div style={this.center}><p style={{ marginTop: 25 }}>UberEATS</p></div></th>
                            <td>
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    onChange={e => this.setState({ uberUsername: e.target.value })}
                                    id="uberUsername"
                                    label="Username"
                                    name="uberUsername"
                                    value={this.state.uberUsername}
                                    errorText={this.state.error.errorId === 'uberUsername' ? 'Please Enter Uber Username' : null}
                                />
                            </td>
                            <td>
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="uberPassword"
                                    label="Password"
                                    name="uberPassword"
                                    onChange={e => this.setState({ uberPassword: e.target.value })}
                                    value={this.state.uberPassword}
                                    errorText={this.state.error.errorId === 'uberPassword' ? 'Please Enter Uber Password' : null}
                                />
                            </td>
                            <td colSpan="2">
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="uberPin"
                                    label="PIN"
                                    name="uberPin"
                                    onChange={e => this.setState({ uberPin: e.target.value })}
                                    value={this.state.uberPin}
                                    errorText={this.state.error.errorId === 'uberPin' ? 'Please Enter Uber PIN' : null}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th><div style={this.center}><p style={{ marginTop: 25 }}>DoorDash</p></div></th>
                            <td>
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="DoorDashUsername"
                                    label="Username"
                                    name="DoorDashUsername"
                                    onChange={e => this.setState({ doorUsername: e.target.value })}
                                    value={this.state.doorUsername}
                                    errorText={this.state.error.errorId === 'doorUsername' ? 'Please Enter DoorDash Username' : null}
                                />
                            </td>
                            <td>
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="DoorDashPassword"
                                    label="Password"
                                    name="DoorDashPassword"
                                    onChange={e => this.setState({ doorPassword: e.target.value })}
                                    value={this.state.doorPassword}
                                    errorText={this.state.error.errorId === 'doorPassword' ? 'Please Enter DoorDash Password' : null}
                                />
                            </td>
                            <td>
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="Tablet-Username"
                                    label="Tablet Username"
                                    name="Tablet-Username"
                                    onChange={e => this.setState({ doorTabletUsername: e.target.value })}
                                    value={this.state.doorTabletUsername}
                                    errorText={this.state.error.errorId === 'doorTabletUsername' ? 'Please Enter DoorDash Tablet Username' : null}
                                />
                            </td>
                            <td>
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="doorpin"
                                    label="PIN"
                                    name="doorpin"
                                    onChange={e => this.setState({ doorPin: e.target.value })}
                                    value={this.state.doorPin}
                                    errorText={this.state.error.errorId === 'doorPin' ? 'Please Enter DoorDash PIN' : null}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th><div style={this.center}><p style={{ marginTop: 25 }}>Grubhub</p></div></th>
                            <td>
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="GrubhubUsername"
                                    label="Username"
                                    name="GrubhubUsername"
                                    onChange={e => this.setState({ grubUsername: e.target.value })}
                                    value={this.state.grubUsername}
                                    errorText={this.state.error.errorId === 'grubUsername' ? 'Please Enter Grubhub Username' : null}
                                />
                            </td>
                            <td colSpan="3">
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="Grubhubpassword"
                                    label="Password"
                                    name="GrubhubPassword"
                                    onChange={e => this.setState({ grubPassword: e.target.value })}
                                    value={this.state.grubPassword}
                                    errorText={this.state.error.errorId === 'grubPassword' ? 'Please Enter Grubhub Password' : null}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th><div style={this.center}><p style={{ marginTop: 25 }}>Postmates</p></div></th>
                            <td>
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="PostmatesUsername"
                                    label="Username"
                                    name="PostmatesUsername"
                                    onChange={e => this.setState({ postUsername: e.target.value })}
                                    value={this.state.postUsername}
                                    errorText={this.state.error.errorId === 'postUsername' ? 'Please Enter Postmates Username' : null}
                                />
                            </td>
                            <td colSpan="3">
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="Postmatespassword"
                                    label="Password"
                                    name="PostmatesPassword"
                                    onChange={e => this.setState({ postPassword: e.target.value })}
                                    value={this.state.postPassword}
                                    errorText={this.state.error.errorId === 'postPassword' ? 'Please Enter Postmates Password' : null}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th><div style={this.center}><p style={{ marginTop: 25 }}>Caviar</p></div></th>
                            <td>
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="CaviarUsername"
                                    label="Username"
                                    name="CaviarUsername"
                                    onChange={e => this.setState({ caviarUsername: e.target.value })} 
                                    value={this.state.caviarUsername}
                                    errorText={this.state.error.errorId === 'caviarUsername' ? 'Please Enter Caviar Username' : null}
                                />
                            </td>
                            <td colSpan="3">
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="Caviarpassword"
                                    label="Password"
                                    name="CaviarPassword"
                                    onChange={e => this.setState({ caviarPassword: e.target.value })}
                                    value={this.state.caviarPassword}
                                    errorText={this.state.error.errorId === 'caviarPassword' ? 'Please Enter Caviar Password' : null}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="5">
                                <div style={{ width: '20%', float: 'right', margin:10 }}>
                                    {this.props.edit.error && this.props.edit.message.length > 0 ? ( // if error
                                        <p className="mb-3" style={{ color: '#FF503E'}}>{this.props.edit.message}</p>
                                    ) : null}
                                    {!this.props.edit.error && this.props.edit.message.length > 0 ? ( // if success
                                        <p className="mb-3" style={{ color: 'green'}}>{this.props.edit.message}</p>
                                    ) : null}

                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        className="btn btn-primary text-uppercase mt-3"
                                        onClick={this.submitEditHandler}
                                    >
                                    Submit
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Accordion>
        )
    }
}

const mapStateToProps = state => {
    return {
        edit: state.editVirtualRestaurant
    }
}

export default connect(mapStateToProps, { editVirtualRestaurant })(withRouter(Edit));