import React from 'react';

import Accordion from '../../Accordion';

const AccountInformation = props => (
    <Accordion title='Account Information'>
        <table className="table table-bordered">
            <tbody>
                <tr>
                    <th style={{ width: '15%' }}>Email</th>
                    <td>{props.restaurant.email}</td>
                    <td colSpan="2">Password :{props.restaurant.accountDetails.emailPassword}</td>
                </tr>
                <tr>
                    <th style={{ width: '15%' }}>UberEATS</th>
                    <td>Username: {props.restaurant.accountDetails.uberEats.username}</td>
                    <td>Password: {props.restaurant.accountDetails.uberEats.password}</td>
                    <td colSpan="2">PIN: {props.restaurant.accountDetails.uberEats.pin}</td>
                </tr>
                <tr>
                    <th style={{ width: '15%' }}>DoorDash</th>
                    <td>Username: {props.restaurant.accountDetails.doorDash.username}</td>
                    <td>Password: {props.restaurant.accountDetails.doorDash.password}</td>
                    <td>Tablet Username: {props.restaurant.accountDetails.doorDash.tabletUsername} </td>
                    <td>PIN: {props.restaurant.accountDetails.doorDash.pin}</td>
                </tr>
                <tr>
                    <th style={{ width: '15%' }}>Grubhub</th>
                    <td>Username: {props.restaurant.accountDetails.grubHub.username}</td>
                    <td colSpan="3">Password: {props.restaurant.accountDetails.grubHub.password}</td>
                </tr>
                <tr>
                    <th style={{ width: '15%' }}>Postmates</th>
                    <td>Username: {props.restaurant.accountDetails.postmates.username}</td>
                    <td colSpan="3">Password: {props.restaurant.accountDetails.postmates.password}</td>
                </tr>
                <tr>
                    <th style={{ width: '15%' }}>Caviar</th>
                    <td>Username:  {props.restaurant.accountDetails.caviar.username}</td>
                    <td colSpan="3">Password:  {props.restaurant.accountDetails.caviar.password}</td>
                </tr>
            </tbody>
        </table>
    </Accordion>
)

export default AccountInformation;