import React from 'react';
import { Link } from 'react-router-dom';

import Accordion from '../../Accordion';

import { objectLength } from '../../../Utility/commonFuncion';

import styles from './VirtualRestaurants.module.css';

const VirtualRestaurants = props => (
    <Accordion title='Virtual Restaurants'>
        <div className="row">
            {objectLength(props.restData.virtualKitchens) > 0 ?
                props.restData.virtualKitchens.map((u, i) => {
                    return (
                        <div key={i} className="col-md-4 text-center">
                            <Link to={`/VirtualRest/${u.restaurantId}`} className={styles.link}>
                                <h6 className={styles.datascitab}>
                                    {u.name}
                                </h6>
                            </Link>
                        </div>
                    )
                })
                : null
            }
        </div>
    </Accordion>
);

export default VirtualRestaurants;