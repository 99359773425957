import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editRest, clearMessage } from '../../../Views/Home/actions';
 
import Accordion from '../../Accordion';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { TextInput } from '../../TextInput';

class EditRestaurant extends Component {

    state = {
        restaurantName: this.props.restData.restaurantName,
        ownerName: this.props.restData.ownerName,
        restaurantNumber: this.props.restData.restaurantNumber,
        cuisine: this.props.restData.restaurantCuisine,
        corporateLegalName: this.props.restData.corporateName,
        email: this.props.restData.email,
        address: this.props.restData.address,
        mondayHours: this.props.restData.hours.M,
        tuesdayHours: this.props.restData.hours.T,
        wednesdayHours: this.props.restData.hours.W,
        thursdayHours: this.props.restData.hours.TR,
        fridayHours: this.props.restData.hours.F,
        saturdayHours: this.props.restData.hours.Sa,
        sundayHours: this.props.restData.hours.Su,
        bankAccount: this.props.restData.bankAccount,
        routingNumber: this.props.restData.routingNumber,
        EIN: this.props.restData.EIN,
        SSN: this.props.restData.SSN,
        dateOfBirth: this.props.restData.dateOfBirth,

        error: { // check for error
            haveError: false,
            errorId: '',
            errorText: ''
        }
    }

    submitEditHandler = () => {
        // Check if each input is filled
        const stateEntries = Object.entries(this.state);
        for(let i = 0; i < stateEntries.length; i++) {
            const entry = stateEntries[i];
            if(entry[1] === '' && entry[0] !== 'error') {
                const error = {
                    haveError: true,
                    errorId: entry[0]
                }
                return this.setState({ error });
            }
        }

        const requestBody = {
            token: window.sessionStorage.getItem('token'),
            restaurantName: this.state.restaurantName,
            ownerName: this.state.ownerName,
            cuisine: "cuisine",
            corporateLegalName: "leagal",
            email: "email",
            address: "address",
            mondayHours: "monday",
            tuesdayHours: "tuesday",
            wednesdayHours: "wednesday",
            thursdayHours: "thursday",
            fridayHours: "friday",
            saturdayHours: "saturday",
            sundayHours: "sunday",
            bankAccount: "bank",
            routingNumber: "route",
            EIN: "123",
            SSN: "321",
            dateOfBirth: "123"
        };

        const id = this.props.restData.restaurantNumber; 
        this.props.editRest(id, requestBody);  
    }

    render() {
        return (
            <Accordion title='Edit Restaurant'>
                <Grid container>
                    <Grid item xs>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        <TextInput
                                            variant="outlined"
                                            margin="normal"
                                            onChange={ e =>  this.setState({ restaurantName: e.target.value }) }
                                            fullWidth
                                            id="restname"
                                            label="Restaurant Name:"
                                            name="restname"
                                            required
                                            value={this.state.restaurantName}
                                            errorText={this.state.error.errorId === 'restaurantName' ? 'Please Enter Restaurant name' : null}
                                        />
                                        <TextInput
                                            variant="outlined"
                                            margin="normal"
                                            onChange={ e =>  this.setState({ corporateLegalName: e.target.value }) }
                                            fullWidth
                                            id="corporateLegalName"
                                            label="Corporate Legal Name:"
                                            name="corporateLegalName"
                                            required
                                            value={this.state.corporateLegalName}
                                            errorText={this.state.error.errorId === 'corporateLegalName' ? 'Please Name' : null}
                                        />
                                        <TextInput
                                            variant="outlined"
                                            margin="normal"
                                            onChange={ e =>  this.setState({ cuisine: e.target.value }) }
                                            fullWidth
                                            id="cuisine"
                                            label="Cuisine:"
                                            name="cuisine"
                                            required
                                            value={this.state.cuisine}
                                            errorText={this.state.error.errorId === 'cuisine' ? 'Please Enter Cusine' : null}
                                        />
                                        <TextInput
                                            variant="outlined"
                                            margin="normal"
                                            onChange={ e =>  this.setState({ ownerName: e.target.value }) }
                                            fullWidth
                                            id="ownername"
                                            label="Owner Name"
                                            name="ownername"
                                            required
                                            value={this.state.ownerName}
                                            errorText={this.state.error.errorId === 'ownerName' ? 'Please Enter Owner name' : null}
                                        />
                                        
                                        <TextInput
                                            variant="outlined"
                                            margin="normal"
                                            onChange={ e =>  this.setState({ email: e.target.value }) }
                                            fullWidth
                                            id="email"
                                            label="Email"
                                            name="email"
                                            required
                                            value={this.state.email}
                                            errorText={this.state.error.errorId === 'email' ? 'Please Enter Email' : null}
                                        />
                                        <TextInput
                                            variant="outlined"
                                            margin="normal"
                                            onChange={ e =>  this.setState({ address: e.target.value }) }
                                            fullWidth
                                            id="address"
                                            label="Address"
                                            name="address"
                                            required
                                            value={this.state.address}
                                            errorText={this.state.error.errorId === 'address' ? 'Please Enter Address' : null}
                                        />
                                        <div className="row">
                                            <div className="col-md-4">
                                                <TextInput
                                                    variant="outlined"
                                                    margin="normal"
                                                    onChange={ e =>  this.setState({ mondayHours: e.target.value }) }
                                                    id="monday"
                                                    label="Monday"
                                                    name="openhour"
                                                    required
                                                    value={this.state.mondayHours}
                                                    fullWidth
                                                    errorText={this.state.error.errorId === 'mondayHours' ? 'Please Enter Hours' : null}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <TextInput
                                                    variant="outlined"
                                                    margin="normal"
                                                    onChange={ e =>  this.setState({ tuesdayHours: e.target.value }) }
                                                    id="tuesday"
                                                    label="Tuesday"
                                                    name="openhour"
                                                    required
                                                    value={this.state.tuesdayHours}
                                                    fullWidth
                                                    errorText={this.state.error.errorId === 'tusedayHours' ? 'Please Enter Hours' : null}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <TextInput
                                                    variant="outlined"
                                                    margin="normal"
                                                    onChange={ e =>  this.setState({ wednesdayHours: e.target.value }) }
                                                    id="wednesday"
                                                    label="Wednesday"
                                                    name="openhour"
                                                    required
                                                    value={this.state.wednesdayHours}
                                                    fullWidth
                                                    errorText={this.state.error.errorId === 'wednesdayHours' ? 'Please Enter Hours' : null}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <TextInput
                                                    variant="outlined"
                                                    margin="normal"
                                                    onChange={ e =>  this.setState({ sundayHours: e.target.value }) }
                                                    id="sunday"
                                                    label="Sunday"
                                                    name="openhour"
                                                    required
                                                    value={this.state.sundayHours}
                                                    fullWidth
                                                    errorText={this.state.error.errorId === 'sundayHours' ? 'Please Enter Hours' : null}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    onChange={ e =>  this.setState({ bankAccount: e.target.value }) }
                                    fullWidth
                                    id="bankaccount"
                                    label="Bank Account"
                                    name="bankaccount"
                                    required
                                    value={this.state.bankAccount}
                                    errorText={this.state.error.errorId === 'bankAccount' ? 'Please Enter Bank Account' : null}
                                />
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    onChange={ e =>  this.setState({ routingNumber: e.target.value }) }
                                    fullWidth
                                    id="routing"
                                    label="Routing"
                                    name="routing"
                                    required
                                    value={this.state.routingNumber}
                                    errorText={this.state.error.errorId === 'routingNumber' ? 'Please Enter Routing Number' : null}
                                />
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    onChange={ e =>  this.setState({ EIN: e.target.value }) }
                                    fullWidth
                                    id="ein"
                                    label="EIN"
                                    name="ein"
                                    required
                                    value={this.state.EIN}
                                    errorText={this.state.error.errorId === 'EIN' ? 'Please Enter EIN' : null}
                                />
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    onChange={ e =>  this.setState({ SSN: e.target.value }) }
                                    fullWidth
                                    id="SSN"
                                    label="SSN"
                                    name="SSN"
                                    required
                                    value={this.state.SSN}
                                    errorText={this.state.error.errorId === 'SSN' ? 'Please Enter SSN' : null}
                                />
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    onChange={ e =>  this.setState({ dateOfBirth: e.target.value }) }
                                    fullWidth
                                    id="dob"
                                    label="Date of Birth"
                                    name="dob"
                                    required
                                    value={this.state.dateOfBirth}
                                    errorText={this.state.error.errorId === 'dateOfBirth' ? 'Please Enter Date Of Birth' : null}
                                />
                                <div className="row">
                                    <div className="col-md-4">
                                        <TextInput
                                            variant="outlined"
                                            margin="normal"
                                            onChange={ e =>  this.setState({ thursdayHours: e.target.value }) }
                                            id="thursday"
                                            label="Thursday"
                                            name="openhour"
                                            required
                                            value={this.state.thursdayHours}
                                            fullWidth
                                            errorText={this.state.error.errorId === 'thursdayHours' ? 'Please Enter Hours' : null}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <TextInput
                                            variant="outlined"
                                            margin="normal"
                                            onChange={ e =>  this.setState({ fridayHours: e.target.value }) }
                                            id="friday"
                                            label="Friday"
                                            name="openhour"
                                            required
                                            value={this.state.fridayHours}
                                            fullWidth
                                            errorText={this.state.error.errorId === 'fridayHours' ? 'Please Enter Hours' : null}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <TextInput
                                            variant="outlined"
                                            margin="normal"
                                            onChange={ e =>  this.setState({ saturdayHours: e.target.value }) }
                                            id="saturday"
                                            label="Saturday"
                                            name="openhour"
                                            required
                                            value={this.state.saturdayHours}
                                            fullWidth
                                            errorText={this.state.error.errorId === 'saturdayHours' ? 'Please Enter Hours' : null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 ml-auto d-flex flex-column align-items-end">
                                {this.props.edit.error && this.props.edit.message.length > 0 ? ( // error
                                    <p className="mr-3" style={{ color: '#FF503E'}}>{this.props.edit.message}</p>
                                 ) : null}
                                {!this.props.edit.error && this.props.edit.message.length > 0 ? ( // success 
                                    <p className="mr-3" style={{ color: 'green'}}>{this.props.edit.message}</p>
                                 ) : null}
                                <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                className="btn btn-primary text-uppercase mt-2"
                                onClick={this.submitEditHandler}
                                style={{ maxWidth: '225px'}}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Accordion>
        )
    }
}

const mapStateToProps = state => {
    return {
        edit: state.editRest
    }
}

export default connect(mapStateToProps, { editRest, clearMessage })(EditRestaurant);