export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const VIRTUAL = `virtual`;
export const VIRTUAL_SUCCESS = `${VIRTUAL}.success`;
export const VIRTUAL_FAILED = `${VIRTUAL}.failed`;
export const VIRTUAL_RESET = `${VIRTUAL}.reset`;

export const EDITVIRTUAL = `editvirtual`;
export const EDITVIRTUAL_SUCCESS = `${EDITVIRTUAL}.success`;
export const EDITVIRTUAL_FAILED = `${EDITVIRTUAL}.failed`;
export const EDITVIRTUAL_RESET = `${EDITVIRTUAL}.reset`;

export const CHANGEONBOARDING = `changeonboarding`;
export const CHANGEONBOARDING_SUCCESS = `${CHANGEONBOARDING}.success`;
export const CHANGEONBOARDING_FAILED = `${CHANGEONBOARDING}.failed`;