import React from 'react';

import Accordion from '../../Accordion';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { objectLength } from '../../../Utility/commonFuncion';

const PaymentHistory = props => (
    <Accordion title='Payment History'>
        {objectLength(props.restData.paymentHistory) > 0 ?
            (
                <Grid container className="mb-5">
                    <TableContainer style={{ maxHeight: 325}}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Date
                                    </TableCell>
                                    <TableCell>
                                        Description
                                    </TableCell>
                                    <TableCell>
                                        Amount
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    props.restData.paymentHistory.map((u, i) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                                <TableCell>
                                                    {u.date}
                                                </TableCell> 
                                                <TableCell>
                                                    {u.note}
                                                </TableCell> 
                                                <TableCell>
                                                    {u.amount}
                                                </TableCell> 
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                        {/* {
                            props.restData.paymentHistory.map((u, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{u.date}</td>
                                        <td>{u.note}</td>
                                        <td>{u.amount}</td>
                                    </tr>
                                )
                            })
                        } */}
                        
                </Grid>
            )
            :
            <p>No Payment History</p>
        }
    </Accordion>
);

export default PaymentHistory;