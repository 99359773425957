import React from 'react';

import Accordion from '../../Accordion';
import { objectLength } from '../../../Utility/commonFuncion';

import postmatesLogo from '../../../assets/img/logos/postmates.png';
import uberEatsLogo from '../../../assets/img/logos/uberEats.png';
import grubhubLogo from '../../../assets/img/logos/grubhub.jpg';
import caviarLogo from '../../../assets/img/logos/caviar.png';
import doorDashLogo from '../../../assets/img/logos/doorDash.jpg'; 

const DeliveryService = props => (
    <Accordion title='Delivery Service'>
        <div className="row">
                {
                    objectLength(props.restaurant.deliveryLinks) > 0 ?
                    Object.entries(props.restaurant.deliveryLinks).map(([k, v]) => {
                        let image;
                        switch(k) {
                            case 'postmates':
                                image = postmatesLogo;
                                break;
                            case 'uberEats':
                                image = uberEatsLogo;
                                break;
                            case 'doorDash':
                                image = doorDashLogo;
                                break;
                            case 'grubhub':
                                image = grubhubLogo;
                                break;
                            case 'caviar':
                                image = caviarLogo;
                                break;
                            default: image = '';
                        }
                        return (
                            <div key={k} className="col-md-3 d-flex justify-content-center align-items-center">
                                <a href={v} target="_blank" rel="noopener noreferrer">
                                    <img src={image} style={{ height: 50, width: 200, objectFit: 'contain' }} alt="delivery" />
                                </a>
                            </div>
                        )
                    })
                    : null
                }
        </div>
    </Accordion>
);


export default DeliveryService;