import React, { Component } from 'react';

import Accordion from '../../Accordion';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { TextInput } from '../../TextInput';

import { objectLength } from '../../../Utility/commonFuncion';

import styles from './Charges.module.css';

class Charges extends Component {

    state = {
        notes: '',
        amount: '',
        errorNotes: '',
        errorAmount: ''
    }

    submitChargeHandler = () => {
        if(this.state.notes === '') return this.setState({ errorNotes: 'Please Enter Notes'});
        if(this.state.amount === '') return this.setState({ errorAmount: 'Please Enter Amount'});
        this.props.makeCharge(this.state.notes, this.state.amount );
    }

    render() {

        return (
            <Accordion title='Charges for Supplies'>
                <Grid container>
                    <Grid item xs>
                        {objectLength(this.props.restData.chargeHistory) > 0 ?
                            (
                            <Grid container className="mb-5">
                                <TableContainer style={{ maxHeight: 325}}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Date
                                                </TableCell>
                                                <TableCell>
                                                    Description
                                                </TableCell>
                                                <TableCell>
                                                    Amount
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.props.restData.chargeHistory.map((u, i) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                                            <TableCell>
                                                                {u.date}
                                                            </TableCell> 
                                                            <TableCell>
                                                                {u.note}
                                                            </TableCell> 
                                                            <TableCell>
                                                                {u.amount}
                                                            </TableCell> 
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>  
                            </Grid>
                            )
                            :
                            <p>No Charges on Account</p> 
                        }
                    </Grid>
                    <Grid item xs={12} className="pt-3">
                        <div className="d-flex justify-content-center align-items-center">
                            <div className={styles.pay}>
                                Make Charge
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3">
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    value={this.state.notes}
                                    onChange={ e => this.setState({ notes: e.target.value, errorNotes: '' })}
                                    fullWidth
                                    id="Notes"
                                    label="Notes"
                                    name="Notes"
                                    errorText={this.state.errorNotes}
                                    required
                                />
                            </div>
                            <div className="col-md-3">
                                <TextInput
                                    variant="outlined"
                                    margin="normal"
                                    value={this.state.amount}
                                    onChange={ e => this.setState({ amount: e.target.value, errorAmount: '' })}
                                    fullWidth
                                    id="Amount"
                                    label="Amount"
                                    name="Amount"
                                    required
                                    errorText={this.state.errorAmount}
                                />
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-md-2 mx-auto">
                                    <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    className="btn btn-primary text-uppercase mt-3"
                                    onClick={this.submitChargeHandler}
                                    >
                                        Submit
                                    </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Accordion>
        );
    }
}

export default Charges;