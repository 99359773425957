export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const CHARGE = `charge`;
export const CHARGE_SUCCESS = `${CHARGE}.success`;
export const CHARGE_FAILED = `${CHARGE}.failed`;
export const CHARGE_RESET = `${CHARGE}.reset`;

export const EDITREST = `EditRest`;
export const EDITREST_SUCCESS = `${EDITREST}.success`;
export const EDITREST_FAILED = `${EDITREST}.failed`;
export const EDITREST_RESET = `${EDITREST}.reset`;

export const FEED = `feed`;
export const FEED_SUCCESS = `${FEED}.success`;
export const FEED_FAILED = `${FEED}.failed`;
export const FEED_RESET = `${FEED}.reset`;

export const RESTAURANT = `restaurant`;
export const RESTAURANT_SUCCESS = `${RESTAURANT}.success`;
export const RESTAURANT_FAILED = `${RESTAURANT}.failed`;
export const RESTAURANT_RESET = `${RESTAURANT}.reset`;

