import axios from 'axios';
import { URL } from '../Constant/url';

export const post = async (url, params, type = 1) => {
    return makeRequest("POST", url, params, type)
    .then(data => data)
    .catch((err) => {
        console.log(err)
        return err;
    });
};

export const get = async (url, params, type = 1) => {
    return makeRequest("GET", url, params, type)
    .then(data => data)
    .catch((err) => {
        console.log(err)
        return err;
    });
};

export const put = async (url, params, type = 1) => {
    return makeRequest("PUT", url, params, type)
    .then(data => data)
    .catch((err) => {
        console.log(err)
        return err;
    });
};

const makeRequest = (method, url, params, type) => {
    if(method === 'POST') {
        const response = axios({
            url: URL + url,
            method: 'POST',
            data: params,
            headers: {
              Accept: 'application/json', 'Content-Type': 'multipart/form-data'
            }
        })
        .catch((ex) => {
            throw ex;
        });
        
        return response;
    } else if(method === 'PUT') {
        let formData = new FormData();
        for (let key in params) {
            formData.append(key, params[key]);
        }
        const response = axios.put(URL + url, formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .catch((ex) => {
            throw ex;
        });
        
        return response;
    } else {
        if(params) {
            url += (url.indexOf("?") === -1 ? "?" : "&") + queryParams(params);
        }
        return axios.get(URL + url);
    }
}

const queryParams = params => {
    let array_params = {};

    Object.keys(params).map(function (obj) {
        if (Array.isArray(params[obj])) {
            array_params = Object.assign(array_params, arrayParams(params, obj));
            delete params[obj];
        }
    });

    let new_params = Object.assign({}, params, array_params);

    return Object.keys(new_params).map(function (k) {
        if (new_params[k] !== undefined && new_params[k] !== null) {
            return encodeURIComponent(k) + "=" + encodeURIComponent(new_params[k]);
        }
    })
    .join("&");
}

const arrayParams = (params, obj) => {
    return Object.assign( {},
        ...Object.keys(params[obj]).map(key => ({
            [obj + "[" + key + "]"]: params[obj][key]
        }))
    );
}