import React from 'react';

import Accordion from '../../Accordion';
import Grid from '@material-ui/core/Grid';

import { objectLength } from '../../../Utility/commonFuncion';

import styles from './DataScience.module.css';

const DataScience = props => (
    <Accordion title='Data Science'>
        <Grid container>
            <Grid item xs>
                <div className="d-flex align-items-center justify-content-center">
                    <div className={styles.pay}> 
                        Top Restaurants
                    </div>
                </div>
                <div className="text-center"> {/*style={this.topdirect} */}
                    {objectLength(props.restData.topRestaurants) > 0 ?
                        props.restData.topRestaurants.map((u, i) => (
                            <h6 key={i} className={styles.datascitab}>
                                <p className="text-center">{u.name}</p>
                                <span style={{ marginRight: 15 }}><a target="_blank" rel="noopener noreferrer" href={u.uberLink.substring(1, u.uberLink.length - 1)}>UberEATS</a> </span>
                                <a target="_blank" rel="noopener noreferrer" href={u.doorDashLink.substring(1, u.doorDashLink.length - 1)}> DoorDash</a>
                                <span style={{ marginLeft: 15 }}><a target="_blank" rel="noopener noreferrer" href={u.yelpLink.substring(1, u.yelpLink.length - 1)}>Yelp</a></span>
                            </h6>
                        ))
                        : null
                    }
                </div>
            </Grid>
            <Grid item xs>
                <div className="d-flex justify-content-center align-items-center">
                    <div className={styles.pay}>
                        Direct Competitors
                    </div>
                </div>
                <div className="text-center"> {/*style={this.topdirect} */}
                    {objectLength(props.restData.directRestaurants) > 0 ?
                        props.restData.directRestaurants.map((u, i) => (
                            <h6 key={i} className={styles.datascitab}>
                                <p className="text-center">{u.name}</p>
                                <span style={{ marginRight: 15 }}><a target="_blank" rel="noopener noreferrer" href={u.uberLink.substring(1, u.uberLink.length - 1)}>UberEATS</a> </span>
                                <a target="_blank" rel="noopener noreferrer" href={u.doorDashLink.substring(1, u.doorDashLink.length - 1)}> DoorDash</a>
                                <span style={{ marginLeft: 15 }}><a target="_blank" rel="noopener noreferrer" href={u.yelpLink.substring(1, u.yelpLink.length - 1)}>Yelp</a></span>
                            </h6>
                        ))
                        : null
                    }
                </div>
            </Grid>
        </Grid>
    </Accordion>
);

export default DataScience;