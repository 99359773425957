import React from 'react';

import Accordion from '../../Accordion';
import Grid from '@material-ui/core/Grid';

const GeneralInformation = props => (
    <Accordion title='General Information'>
        <Grid container>
            <Grid item xs>
                <h6>Owner Name: {props.restData.ownerName}</h6>
                <h6>Email: {props.restData.email}</h6>
                <h6>Restaurant cuisine: {props.restData.restaurantCuisine}</h6>
            </Grid>
            <Grid item xs>
                <h6>Phone Number: {props.restData.ownerNumber}</h6>
                <h6 style={{ width: "70%" }}>Full Address: {props.restData.address}</h6>
            </Grid>
        </Grid>
    </Accordion>
);

export default GeneralInformation;