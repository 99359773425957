import React from 'react';

import Accordion from '../../Accordion';
import Button from '@material-ui/core/Button';

const Create = props => {
    const title = <span style={{ color: "red" }}>  Create New Virtual Restaurant</span>;

    return (
        <Accordion title={title}>
            <div className="row">
                <div className="col-md-3">
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="btn btn-primary text-uppercase mt-3"
                    href={props.restData.newKitchenForm}
                    >
                        Create
                    </Button>
                </div>
            </div>
        </Accordion>
        
    ) 

    
}

export default Create;