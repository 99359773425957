import React, { Component, Fragment } from 'react';

import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";

import { postCharge, search, getRestaurant } from './actions';

import 'react-accessible-accordion/dist/fancy-example.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'; 

import Sidebar from '../../Components/HomeComponents/Sidebar/Sidebar';
import Logout from '../../Components/Logout/Logout';
import EditRestaurant from '../../Components/HomeComponents/EditRestaurant/EditRestaurant';
import GeneralInformation from '../../Components/HomeComponents/GeneralInformation/GeneralInformation';
import AccountInformations from '../../Components/HomeComponents/AccountInformations/AccountInformations';
import PaymentHistory from '../../Components/HomeComponents/PaymentHistory/PaymentHistory';
import Charges from '../../Components/HomeComponents/Charges/Charges';
import DataScience from '../../Components/HomeComponents/DataScience/DataScience';
import VirtualRestaurants from '../../Components/HomeComponents/VirtualRestaurants/VirtualRestaurants';
import CreateVirtualRestaurant from '../../Components/HomeComponents/CreateVirtualRestaurant/CreateVirtualRestaurant';

import styles from './Home.module.css';

class Home extends Component {
    state = {
        token: window.sessionStorage.getItem("token"),
        isloading: false, // this is for restaurant data on the right
        restaurantArray: [],
        restaurantsLoading: true, // this is for all restaurants on sidebar
        term: "",
        restData: null,
    }

    componentDidMount = () => {
        const token = this.state.token;
        if(!token) return false;

        const request = {
            token ,
            term: '*'
        }
        this.props.search(request).then(() => {
            this.setState({ restaurantArray: this.props.restaurantArray.data.feedData, token, restaurantsLoading: false, restData: null  });
        });
    }

    // Getting restaurant by id ::method
    getRestaurantById = id => {
        this.setState({ isloading: true, restData: null });
        const data = {
            token: this.state.token
        };
        this.props.getRestaurant(data, id)
        .then(() => {
            this.setState({ restData: this.props.restaurantData, isloading: false });
        });
    }

    //update charges ::method
    submitMakeCharge = (notes,amount) => {
        // check if notes and amount are empty ?
        var data = {
            token: this.state.token,
            chargeNote: notes,
            chargeAmount: amount
        }
        const id = this.state.restData.restaurantNumber;

        this.props.postCharge(data, id).then(() => {
            console.log('charge added');
        });
    }

    searchCuisine = value => {
        this.setState({ restaurantArray: [], restaurantsLoading: true, term: value }, () => {
            if(this.state.term.length < 1) return false;
            const request = {
                token: window.sessionStorage.getItem("token"),
                term: this.state.term
            }
            this.props.search(request).then(() => {
                this.props.restaurantArray.request.status === 200 ? 
                this.setState({ restaurantArray: this.props.restaurantArray.data.feedData, restaurantsLoading: false }) : 
                this.setState({ restaurantArray: [], term: value, restaurantsLoading: false });
            });
        });
    }

    render() {
        const restData = this.state.restData;

        return (
            this.state.token ? 
            (
            <div className="d-flex w-100 ml-0 position-absolute h-auto" style={{ border: '5px solid #FF503E', minHeight: 'calc(100vh - 10px)'}}>
                <Logout/>
                <Sidebar 
                restaurants={this.state.restaurantArray} 
                term={this.state.term} 
                search={this.searchCuisine}
                isLoading={this.state.restaurantsLoading}
                getRestaurant={id => this.getRestaurantById(id)}/>
                {/* Main Content  */}
                <div className="h-100 position-relative" style={{ flex: 1, padding: '0 15px', border: '0px 5px 0px 0px solid #FF503E', borderLeft: '5px solid #FF503E', minHeight: 'calc(100vh - 10px)' }}>
                    { 
                    restData ? (
                        <Fragment>
                            <h3 className={styles.headerTitle}>
                                {restData.restaurantName}
                            </h3>
                            <hr />
                            <GeneralInformation restData={restData}/>
                            <AccountInformations restData={restData}/>
                            <PaymentHistory restData={restData} />
                            <Charges restData={restData} makeCharge={(notes,amount) => this.submitMakeCharge(notes,amount)} />
                            <EditRestaurant restData={restData} />
                            <DataScience restData={restData} />
                            <VirtualRestaurants restData={restData} />     
                            <CreateVirtualRestaurant restData={restData} />
                        </Fragment>
                    ) :
                    // show spinner if different restaurant is selected
                    this.state.isloading ? 
                    (
                        <div className="position-absolute h-100 d-flex align-items-center justify-content-center" style={{ minHeight: 'calc(100vh - 10px)', width: 'calc(100% - 30px)'}} >
                            <CircularProgress className={styles.spinner} />
                        </div>
                    )
                        : null
                    }
                </div>
            </div>
            )
            :
            <Redirect to="/" />
        )
    }
}

const mapStateToProps = state => {
    return {
        restaurantArray: state.searchfeed.data,
        restaurantData: state.restaurantById.data.data
    };
};

export default connect(mapStateToProps, { postCharge, search, getRestaurant} )((Home));