import * as actionTypes from "./actionTypes";
import { get, post } from "../../Helpers/request";
import * as actionHandlers from "../../Helpers/actionHandler";
import { URL } from '../../Constant/url';

const postCharge = (data,id) => {
    return dispatch => {
        dispatch(actionHandlers.tryHandle(actionTypes.CHARGE));
        return post(`charge/${id}`, data)
        .then(json => dispatch(actionHandlers.handleResponse(actionTypes.CHARGE_SUCCESS, json)))
        .catch(error =>dispatch(actionHandlers.handleError(actionTypes.CHARGE_FAILED, error)));
    }
}

const editRest = (id,data) => {
    return async dispatch => {
        try {
            const editData = await fetch(`${URL}restaurant/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify(data)
            });

            const status = editData.status;
            if(status !== 200) return dispatch(editRestFail('Updating restaurant failed')); 

            const res = await editData.text(); // change to json()
            
            dispatch(editRestSuccess(res));

            setTimeout(() => {
                dispatch(clearMessage());
            }, 2000);
            
        } catch(err) {
            dispatch(editRestFail(err));
        }
    }
}

const search = (data) => {
    return dispatch => {
        dispatch(actionHandlers.tryHandle(actionTypes.FEED));
        return get(`feed`,  data )
        .then(json => dispatch(actionHandlers.handleResponse(actionTypes.FEED_SUCCESS, json)))
        .catch(error => dispatch(actionHandlers.handleError(actionTypes.FEED_FAILED, error)))
    };
};

const getRestaurant = (data,id) => {
    return dispatch => {
        dispatch(actionHandlers.tryHandle(actionTypes.RESTAURANT));
        return get(`restaurant/${id}`,  data )
        .then(json => dispatch( actionHandlers.handleResponse(actionTypes.RESTAURANT_SUCCESS, json)))
        .catch(error => dispatch(actionHandlers.handleError(actionTypes.RESTAURANT_FAILED, error)))
    }
}

const editRestFail = message => {
    return  {
        type: actionTypes.EDITREST_FAILED,
        message
    }
}

const editRestSuccess = data => {
    return  {
        type: actionTypes.EDITREST_SUCCESS,
        message: data
    }
}

const clearMessage = () => {
    return  {
        type: actionTypes.CLEAR_MESSAGE
    }
}

export { postCharge,editRest,search,getRestaurant, clearMessage };