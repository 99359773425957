import * as actionTypes from './actionTypes';

const initState = {
    isLoading: false,
    data: {},
    error: false,
    message: ''
};

const vitualRestaurantById = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.VIRTUAL:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.VIRTUAL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.data,
                error: false
            };
        case actionTypes.VIRTUAL_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload.data
            };
        default: return state;

    }
};

const editVirtualRestaurant = (state = initState, action)  => {
    switch (action.type) {
        case actionTypes.EDITVIRTUAL:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.EDITVIRTUAL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.data,
                error: false
            };
        case actionTypes.EDITVIRTUAL_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true,
                message: action.message
            };
        case actionTypes.CLEAR_MESSAGE:
            return {
                ...state,
                error: false,
                message: ''
            };
        // i dont know why previous developer did this setup but i will add onboarding status actions here since there is no need to add new state
        case actionTypes.CHANGEONBOARDING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.data,
                error: false
            };
        case actionTypes.CHANGEONBOARDING_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true,
                message: action.message
            };
        default: return state;
    }
}

// const changeOnboardingVirtual = (state = initState, action)  => {
//     switch (action.type) {
//         case actionTypes.CHANGEONBOARDING_SUCCESS:
//             return {
//                 ...state,
//                 error: false,
//                 message: action.data
//             };
//         case actionTypes.CHANGEONBOARDING_FAILED:
//             return {
//                 ...state,
//                 error: true,
//                 message: action.message
//             };
//         case actionTypes.CLEAR_MESSAGE:
//             return {
//                 ...state,
//                 error: false,
//                 message: ''
//             };
//         default: return state;
//     }
// }

export{ vitualRestaurantById,editVirtualRestaurant };