import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { changeOnboardingStatus } from '../../../Views/VirtualRest/actions';

import Accordion from '../../Accordion';
import Grid from '@material-ui/core/Grid';

class OnboardingStatus extends Component {

    state = {
        createdEmail: this.props.restaurant.createdEmail,
        boughtDomain: this.props.restaurant.boughtDomain,
        boughtTwilio: this.props.restaurant.boughtTwilio,
        contactedPlatform: this.props.restaurant.contactedPlatform,
        signedContract: this.props.restaurant.signedContract,
        ordermarkOrdered: this.props.restaurant.ordermarkOrdered,
        photosComplete: this.props.restaurant.photosComplete,
        packagingOrdered: this.props.restaurant.packagingOrdered,
        packagingDelivered: this.props.restaurant.packagingDelivered,
        launched: this.props.restaurant.launched
    }
    
    submitChangeHandler = () => {
        const id = this.props.match.params.restaurantId;

        const data = {
            createdEmail:this.state.createdEmail,
            boughtDomain: this.state.boughtDomain,
            boughtTwilio: this.state.boughtTwilio,
            contactedPlatform:this.state.contactedPlatform,
            launched: this.state.launched,
            ordermarkOrdered:this.state.ordermarkOrdered,
            packagingDelivered:this.state.packagingDelivered,
            packagingOrdered: this.state.packagingOrdered,
            photosComplete:this.state.photosComplete,
            signedContract:this.state.signedContract,
            token: parseInt(id)
        }

        this.props.changeOnboardingStatus(id, data);
    }

    render() {
        return (
            <Accordion title='Onboarding Status'>
                <Grid container>
                    <Grid item xs>
                        <p>
                            <input type="checkbox" id="email" checked={this.state.createdEmail ? true : false} onChange={e => this.setState({ createdEmail: !this.state.createdEmail })} /> 
                            <label htmlFor="email">Created Email</label>
                        </p>
                        <p>
                            <input type="checkbox" id="Domain" checked={this.state.boughtDomain ? true : false  } onChange={e => this.setState({ boughtDomain: !this.state.boughtDomain })} /> 
                            <label htmlFor="Domain"> Bought Domain</label>
                        </p>
                        <p>
                            <input type="checkbox" id="Number" checked={this.state.boughtTwilio ? true : false } onChange={e => this.setState({ boughtTwilio: !this.state.boughtTwilio })} /> 
                            <label htmlFor="Number"> Bought Twilio Number</label>
                        </p>
                        <p>
                            <input type="checkbox" checked={this.state.contactedPlatform ? true : false } id="Platforms" onChange={e => this.setState({ contactedPlatform: !this.state.contactedPlatform })} /> 
                            <label htmlFor="Platforms"> Contacted Platforms</label>
                        </p>
                        <p>
                            <input type="checkbox" checked={this.state.signedContract ? true : false } id="Signed" onChange={e => this.setState({ signedContract: !this.state.signedContract })}/> 
                            <label htmlFor="Signed"> Signed Delivery Contracts</label>
                        </p>
                    </Grid>
                    <Grid item xs>
                        <p>
                            <input type="checkbox" checked={this.state.ordermarkOrdered ? true : false } name="mark" id="mark" onChange={e => this.setState({ ordermarkOrdered: !this.state.ordermarkOrdered })} /> 
                            <label htmlFor="mark"> OrderMark Ordered</label>
                        </p>
                        <p>
                            <input type="checkbox" checked={this.state.photosComplete ? true : false} id="Photoshoot" onChange={e => this.setState({ photosComplete: !this.state.photosComplete })} />  
                            <label htmlFor="Photoshoot"> Photoshoot Complete</label>
                        </p>
                        <p>
                            <input type="checkbox" checked={this.state.packagingOrdered ? true : false} id="Packaging" onChange={e => this.setState({ packagingOrdered: !this.state.packagingOrdered })} /> 
                            <label htmlFor="Packaging"> Packaging Ordered</label>
                        </p>
                        <p>
                            <input type="checkbox" checked={this.state.packagingDelivered ? true : false} id="Delivered" onChange={e => this.setState({ packagingDelivered: !this.state.packagingDelivered })} /> 
                            <label htmlFor="Delivered"> Packaging Delivered</label>
                        </p>
                        <p>
                            <input type="checkbox" checked={this.state.launched ? true : false} id="Launched" onChange={e => this.setState({ launched: !this.state.launched })} /> 
                            <label htmlFor="Launched"> Launched</label>
                        </p>
                    </Grid>
                </Grid>
                <div className="mt-3 col-md-2 mr-auto">
                    {this.props.change.error && this.props.change.message.length > 0 ? ( // if error
                        <p className="mb-3" style={{ color: '#FF503E'}}>{this.props.change.message}</p>
                    ) : null}
                    {!this.props.change.error && this.props.change.message.length > 0 ? ( // if error
                        <p className="mb-3" style={{ color: 'green'}}>{this.props.change.message}</p>
                    ) : null}
                    <button className="btn btn-primary w-100" onClick={this.submitChangeHandler}>
                        Submit
                    </button>
                </div>
            </Accordion>
        )
    }
} 

const mapStateToProps = state => {
    return {
        change: state.editVirtualRestaurant
    }
}

export default connect(mapStateToProps,{ changeOnboardingStatus })(withRouter(OnboardingStatus));