import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import styles from './Logout.module.css';

class Logout extends Component {

    render() {
        return (
            <Link to="/" className={styles.logout}
            onClick={() => sessionStorage.removeItem('token')}>
                <div className={styles.exitApp}>
                    <p>Logout <ExitToAppIcon /></p>
                </div>
            </Link>
        )
    }
}

export default Logout;