import * as actionTypes from './actionTypes';

const initState = {
    isLoading: false,
    data: {},
    error: false
};

const login = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.data,
                error: false
            };
        case actionTypes.LOGIN_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload.data
            };
        default:
            return state; 
    }
}

export { login };