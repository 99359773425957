import * as actionTypes from "./actionTypes";
import { post } from "../../Helpers/request";
import * as actionHandlers from "../../Helpers/actionHandler";

const doLogin = data => {
    return dispatch => {
        dispatch(actionHandlers.tryHandle(actionTypes.LOGIN));
        return post(`login`,  data )
            .then(json => dispatch(actionHandlers.handleResponse(actionTypes.LOGIN_SUCCESS, json)))
            .catch(error => dispatch(actionHandlers.handleError(actionTypes.LOGIN_FAILED, error)));
    }
}

export { doLogin };