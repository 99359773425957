import React from 'react';
import ReactDOM from 'react-dom';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './Redux/store';
import thunk from 'redux-thunk';

import Navigation from './Navigation';

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
    <Provider store={store}>
        <Navigation/>
    </Provider>,
document.getElementById('root'));

