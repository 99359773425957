import React from 'react';

import TextField from '@material-ui/core/TextField';

import styles from './TextInput.module.css';

const TextInput = ({ errorText, ...props }) => {


    return (
        <div style={{ padding: 1, width: '100%', }}>
            <TextField {...props} />
            { errorText !== "" ? <p className={styles.errorMsg}>{errorText}</p> : null }
        </div>

    )
};

export {TextInput};
