import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { doLogin } from "./actions";

import Logo from '../../assets/img/Logo.png';

import './Login.css';

class SignIn extends Component {

    state = {
        token: window.sessionStorage.getItem('token'),
        username: '',
        password: '',
        fetching: false,
        error: ""
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.loginResponse !== this.props.loginResponse) {
            // Check if 401 status
            const status = nextProps.loginResponse.request.status;
            if(status === 401) return this.setState({ error: "Invalid username or password" });

            window.sessionStorage.setItem("token", nextProps.loginResponse.data.token );
            this.props.history.push("/Home");
        }

        return true;
    }

    changeLoginDataHandler = e => {
        const value = e.target.value;
        e.target.id === 'username' ? this.setState({ username: value }) : this.setState({ password: value });
    }

    doLogin = e => {
        e.preventDefault();
        if (!this.state.username || !this.state.password ) return this.setState({ error: 'Please enter username and password'});
        const data = { userName:this.state.username, password: this.state.password };
        this.props.doLogin(data);
    }

    render() {
        return (
            !this.state.token ? 
            (
                <div className="login-background">
                    <div className="login-wrapper">
                        <div className="login-wrapper-container d-flex justify-content-center align-items-center">
                            <div className="login-cta d-flex flex-column align-items-center">
                                <img src={Logo} alt="logo" className="login-logo img-fluid"/>
                                <form className="d-flex flex-column align-items-center" onSubmit={this.doLogin}>
                                    {this.state.error ? (
                                        <p className="login-error-message">Invalid username or password</p>
                                    ) : null}
                                    <div className="form-group w-100">
                                        <input type="text" 
                                        className="form-control" 
                                        id="username" 
                                        placeholder="username"
                                        value={this.state.username}
                                        onChange={this.changeLoginDataHandler} />
                                    </div>
                                    <div className="form-group w-100">
                                        <input type="password"
                                        className="form-control"
                                        id="password" 
                                        placeholder="password"
                                        value={this.state.password}
                                        onChange={this.changeLoginDataHandler} />
                                    </div>
                                    <button type="submit" className="btn btn-primary text-uppercase mt-3">Login</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            ) 
            : 
            <Redirect to='/Home' />
        );
    }

}

const mapStateToProps = state => {
    const { loading } = state.auth;
    return {
        loginResponse: state.auth.data,
        loading
    };
};

export default connect( mapStateToProps, { doLogin } )(withRouter(SignIn));