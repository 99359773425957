
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


import { TextInput } from '../../TextInput';

import styles from './Sidebar.module.css';

class Sidebar extends Component {

    state = {
        search: '',
        activeId: null
    }

    getRestaurantHandler = (e,id) => {
        this.setState({ activeId: id });
        this.props.getRestaurant(id);
    }

    searchHandler = e => {
        const value = e.target.value;
        this.setState({ search: value });
        this.props.search(value === '' ? '*' : value);
    }

    render() {
        
        return (
            <div className={styles.sidebar}>
                <header style={{ padding: '0 15px' }}>
                    <Link to="/Home">
                        <img src={require("../../../assets/img/logo-2.png")} height="50" className="img-fluid" alt="logo" style={{ marginTop: 10 }} />
                    </Link>
                    <TextInput
                        variant="outlined"
                        margin="normal"
                        onChange={e => this.searchHandler(e)}
                        value={this.state.search}
                        fullWidth
                        id="Search"
                        label="Search"
                        name="Search"
                        style={{ marginBottom: 0 }}
                    />
                </header>
                <hr />
                <List className={styles.list}>
                    {
                        this.props.isLoading ? 
                        (
                            <div className="d-flex justify-content-center">
                                <CircularProgress className={styles.spinner} />
                            </div>
                        ) 
                        : 
                        (
                            this.props.restaurants.length > 0 ?
                            this.props.restaurants.map((u, i) => (
                                <ListItem key={i} className={ `${styles.tab} ${this.state.activeId === u.restaurantId ? styles.activeTab : null}`} id={u.restaurantId} onClick={e => this.getRestaurantHandler(e, u.restaurantId) }>
                                    <ListItemText primary={u.restaurantName} secondary={u.restaurantCity} />
                                </ListItem>
                            ))
                            :
                            <p style={{ marginLeft: 15 }}>No restaurants found</p>
                        )
                    }
                </List>
            </div>
        )
    }
}


export default Sidebar;