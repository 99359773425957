import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from "../Views/Login";
import Home from "../Views/Home";
import Virtual from '../Views/VirtualRest'

import './App.css';

const App = () => (
    <Router>
        <Switch>
            <Route path="/Login">
                <Login />
            </Route>
            <Route path="/Home" >
                <Home />
            </Route>
            <Route path="/VirtualRest/:restaurantId" exact component={ Virtual } />
            <Route path="/">
                <Login />
            </Route>
        </Switch>
    </Router>
)


export default App;