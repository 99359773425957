import * as actionTypes from "./actionTypes";
import { get } from "../../Helpers/request";
import * as actionHandlers from "../../Helpers/actionHandler";
import { URL } from '../../Constant/url';

// Old code
const getVitualRestaurant = (data,id) => {
    return dispatch => {
        dispatch(actionHandlers.tryHandle(actionTypes.VIRTUAL));
        return get(`/virtual/${id}`,  data )
        .then(json => dispatch( actionHandlers.handleResponse(actionTypes.VIRTUAL_SUCCESS, json)))
        .catch(error => dispatch(actionHandlers.handleError(actionTypes.VIRTUAL_FAILED, error)));
    }
};

// New code
const changeOnboardingStatus = (id,data) => {
    console.log(data)
    return async dispatch => {
        try {
            const edit = await fetch(`${URL}virtual/onboard/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify(data)
            });

            const status = edit.status;
            if(status !== 200) return dispatch(changeOnboardingFail('Server error'));

            const res = await edit.text();

            dispatch(changeOnboardingSuccess(res));
            setTimeout(() => {
                dispatch(clearMessage());
            }, 2000);
        } catch(err) {
            dispatch(changeOnboardingFail(err));
            setTimeout(() => {
                dispatch(clearMessage());
            }, 2000);
        }
    }
}

const editVirtualRestaurant = (id,data) => {
    return async dispatch => {
        try {
            const edit = await fetch(`${URL}virtual/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const status = edit.status;
            console.log(status)
            if(status !== 200) return dispatch(editFail('Request failed'));

            const res = await edit.text();
            dispatch(editSuccess(res));
            setTimeout(() => {
                dispatch(clearMessage());
            }, 2000);

        } catch(err) {
            dispatch(editFail('Request failed'));
            setTimeout(() => {
                dispatch(clearMessage())
            }, 2000);
        }
    }
}

const editFail = message => {
    return {
        type: actionTypes.EDITVIRTUAL_FAILED,
        message
    }
}

const editSuccess = data => {
    return {
        type: actionTypes.EDITVIRTUAL_SUCCESS,
        data
    }
}

const changeOnboardingFail = message => {
    return {
        type: actionTypes.CHANGEONBOARDING_FAILED,
        message
    }
}

const changeOnboardingSuccess = data => {
    return {
        type: actionTypes.CHANGEONBOARDING_SUCCESS,
        data
    }
}

const clearMessage = () => {
    return {
        type: actionTypes.CLEAR_MESSAGE
    }
}



export { getVitualRestaurant,editVirtualRestaurant, changeOnboardingStatus };