import React from 'react';

import Accordion from '../../Accordion';
import Grid from '@material-ui/core/Grid';

import { objectLength } from '../../../Utility/commonFuncion';

import styles from './AccountInformation.module.css';

const AccountInformations = props => (
    <Accordion title='Account Information'>
        <Grid container>
            <Grid item xs>
                <h6>Owner Name: {props.restData.ownerName}</h6>
                <h6>Email: {props.restData.email}</h6>
                <h6>Corporate Legal Name: {props.restData.corporateName}</h6>
                <h6>EIN: {props.restData.EIN}</h6>
                <h6>Bank Account: {props.restData.bankAccount}</h6>
            </Grid>
            <Grid item xs>
                <h6>Phone Number: {props.restData.ownerNumber}</h6>
                <h6 style={{ width: "70%" }}>Full Address: {props.restData.address}</h6>
                <h6>Date of Birth: {props.restData.dateOfBirth}</h6>
                <h6>SSN: {props.restData.SSN}</h6>
                <h6>Routing: {props.restData.routingNumber}</h6>
            </Grid>
        </Grid>
        <h6 style={{ color: 'red' }}>Open Hours:</h6>
        {
            objectLength(props.restData.hours) > 0 ?
                (
                    <div className="row">
                        <div className="col-md-3 mr-auto">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td className={styles.day}>Monday</td>
                                        <td className={styles.td}>{props.restData.hours.M}</td>
                                    </tr>
                                    <tr>
                                        <td className={styles.day}>Tuesday</td>
                                        <td className={styles.td}>{props.restData.hours.T}</td>
                                    </tr>
                                    <tr>
                                        <td className={styles.day}>Wednesday</td>
                                        <td className={styles.td}>{props.restData.hours.W}</td>
                                    </tr>
                                    <tr>
                                        <td className={styles.day}>Thursday</td>
                                        <td className={styles.td}>{props.restData.hours.TR}</td>
                                    </tr>
                                    <tr>    
                                        <td className={styles.day}>Friday</td>
                                        <td className={styles.td}>{props.restData.hours.F}</td>
                                    </tr>
                                    <tr>
                                        <td className={styles.day}>Saturday</td>
                                        <td className={styles.td}>{props.restData.hours.Sa}</td>
                                    </tr>
                                    <tr>
                                        <td className={styles.day}>Sunday</td>
                                        <td className={styles.td}>{props.restData.hours.Su}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )
                : null
        }
    </Accordion>
);

export default AccountInformations;