import * as actionTypes from './actionTypes';

const initState = {
    isLoading: false,
    data: {},
    error: false,
    message: ''
}

const charge = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.CHARGE:
            return {
                ...state,
                isLoading: true
            }
        case actionTypes.CHARGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.data,
                error: false
            };
        case actionTypes.CHARGE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload.data
            };
        default: 
            return state;
    }
}

const editRest = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.EDITREST:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.EDITREST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                message: action.message,
                error: false
            };
        case actionTypes.EDITREST_FAILED:
            return {
                ...state,
                isLoading: false,
                error: true,
                message: action.message
            };
        case actionTypes.CLEAR_MESSAGE:
            return {
                ...state,
                error: false,
                message: ''
            }
        default:
            return state;
    }
}

const searchfeed = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.FEED:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.FEED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.data,
                error: false
            };
        case actionTypes.FEED_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload.data
            };
        default:
            return state;
    }
}

const restaurantById = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.RESTAURANT:
            return {
                ...state,
                isLoading: true
            };
        case actionTypes.RESTAURANT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.data,
                error: false
            };
        case actionTypes.RESTAURANT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload.data
            };
        default:
            return state;
    }
}

export{ charge, editRest, searchfeed, restaurantById };