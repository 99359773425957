import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { getVitualRestaurant, editVirtualRestaurant } from './actions'
import ReactLoading from 'react-loading';

import 'react-accessible-accordion/dist/fancy-example.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from '../../Components/VRComponents/Header/Header';
import Logout from '../../Components/Logout/Logout';
import GeneralInformation from '../../Components/VRComponents/GeneralInformation/GeneralInformation';
import OnboardingStatus from '../../Components/VRComponents/OnboardingStatus/OnboardingStatus';
import AccountInformation from '../../Components/VRComponents/AccountInformation/AccountInformation';
import DeliveryService from '../../Components/VRComponents/DeliveryService/DeliveryService';
import DataScience from '../../Components/VRComponents/DataScience/DataScience';
import Edit from '../../Components/VRComponents/Edit/Edit';

class Virtual extends Component {

    state = {
        isloading: true,
        token: window.sessionStorage.getItem("token"),
        restaurantArray: []
    }

    componentDidMount = () => {
        
        const token = this.state.token;
        if(!token) return false;
        const request = { token };
        this.props.getVitualRestaurant(request, this.props.match.params.restaurantId).then(() => {
            this.setState({ restaurantArray: this.props.restaurantArray, token, isloading: false })
        });
    }

    render() {
        const virtual = this.state.restaurantArray;
        const notAuthLayout = <Redirect to="/Login" />;
        const authLayout = (
            this.state.isloading ? 
            (
                // show spinner if loading
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', width: '100vw' ,transform: 'translateY(-80px)'}}>
                    <ReactLoading type={"bars"} color={"#FF503E"} height={10} width={100} />
                </div>
            ) :
            ( 
                virtual ? 
                (
                    // Layout when data is loaded
                        <div className="container-fluid position-relative w-100 ml-0" style={{ border: '5px solid #FF503E', minHeight: '100vh'}}>
                        <Logout/>
                        <Header restaurantName={virtual.restaurantName} />
                        <div className="row">
                            <div className="col-md-12" style={{ padding: 15 }}>
                                <GeneralInformation restaurant={virtual} />
                                <OnboardingStatus restaurant={virtual}/>
                                <AccountInformation restaurant={virtual} />
                                <DeliveryService restaurant={virtual} />
                                <DataScience restaurant={virtual} />
                                <Edit restaurant={virtual}  />
                            </div>
                        </div>
                    </div>
                )
                : null
            )
        )
        return this.state.token ? authLayout : notAuthLayout;
    }
}

const mapStateToProps = state => {
    return {
        loginResponse: state.auth.data.data,
        restaurantArray: state.vitualRestaurantById.data.data
    };
};

export default connect( mapStateToProps, { getVitualRestaurant, editVirtualRestaurant })((Virtual));