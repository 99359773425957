import React from 'react';

import Accordion from '../../Accordion';
import Grid from '@material-ui/core/Grid';

const GeneralInformation = props => (
    <Accordion title='General Information'>
        <Grid container>
            <Grid item xs>
                <h6>Name: Jack</h6>
                <h6>Cuisine: {props.restaurant.cuisine}</h6>
                <h6>Email: {props.restaurant.email}</h6>
                <h6>Phone: {props.restaurant.phoneNumber} </h6>
            </Grid>
            <Grid item xs>
                <h6><a href={props.restaurant.website} target="_blank" rel="noopener noreferrer">Website</a></h6>
                <h6> <a href={props.restaurant.supportCallNumber} target="_blank" rel="noopener noreferrer">Support Call Numbers</a></h6> 
                <h6> <a href={props.restaurant.menuLink} target="_blank" rel="noopener noreferrer">Menu Link</a></h6>
                <h6> <a href={props.restaurant.kitchenInstructionsLink} target="_blank" rel="noopener noreferrer">Kitchen Instructions</a> </h6>
                <h6> <a href={props.restaurant.logoFilesLink} target="_blank" rel="noopener noreferrer">Logo Files</a></h6>
                <h6> <a href={props.restaurant.foodPicturesLink} target="_blank" rel="noopener noreferrer">Food Pictures</a> </h6>
            </Grid>
        </Grid>
    </Accordion>
);

export default GeneralInformation;