import React from 'react';

import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';

import './Accordion.css';

const CustomAccordion = props => (
    <Accordion allowZeroExpanded={true}>
        <AccordionItem style={{ margin: 5 }}>
            <AccordionItemHeading>
                <AccordionItemButton>
                    {props.title}
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                {props.children}
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
)

export default CustomAccordion;