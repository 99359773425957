import { combineReducers } from "redux";
import { login } from '../Views/Login/reducers';
import { vitualRestaurantById ,editVirtualRestaurant } from '../Views/VirtualRest/reducers';
import { charge ,editRest,searchfeed,restaurantById} from '../Views/Home/reducers';

const allReducers = combineReducers({
    auth: login,
    charge:charge,
    editRest:editRest,
    searchfeed:searchfeed,
    restaurantById:restaurantById,
    vitualRestaurantById:vitualRestaurantById,
    editVirtualRestaurant:editVirtualRestaurant
});

const rootReducer = (state, action) => {
    if (action.type === "LOGOUT") state = undefined;
    return allReducers(state, action);
}

export default rootReducer;
